import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  Tab,
  TablePagination,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AutoGeneratedEOC from "../../components/AutoGeneratedEOC";
import OnDemandEOC from "../../components/OnDemandEOCCard";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import {
  downloadAllReportFunc,
  getAllPayersFunc,
  getCptCodesSummaryFunc,
  getCptTabsCountFunc,
  getReportCptCodesListFunc,
  getReportsListFunc,
  getReportStatsFunc,
  resetDownloadAll,
} from "../../actions/Reports";
import { useSelector } from "react-redux";
import BillingComingSoon from "../../assets/images/billing-coming-soon-image.png";
import CptCodesComingSoon from "../../assets/images/cpt-codes-coming-soon.png";
import DownloadSelectedReportButton from "../../components/DownloadSelectedReportButton";
import {
  formatElapsedTime,
  getReportDateRanges,
  payerOptions,
  reportMonthFilterOptions,
} from "../../utils/helpers";
import CustomSelectFilter from "../../common/CustomSelectFilter";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import dayjs from "dayjs";
import { CancelOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import InProgressCptCode from "../../components/InProgressCptCode";
import GeneratedCptCode from "../../components/GeneratedCptCode";
import { getUnpaginatedAgentsListFunc } from "../../actions/Agency";
import Cookies from "js-cookie";
import RectangleSkeletons from "../../components/RectangleSkeletons";
import CptSummaryBox from "../../components/CptSummaryBox";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: "transparent",
  //   boxShadow: "none",
  width: "100%",
  padding: "0",
  zIndex: "1",
  position: "relative",
  borderRadius: "0",
  minHeight: "42px",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  // height: "100%",
  // minHeight: "30px !important",
  //   width: "max-content",
  "& .MuiTabScrollButton-root": {
    color: "rgba(109, 125, 139, 1)",
    // borderBottom: "3px solid #CCD2E3",
    opacity: "1 !important",
  },
  "& .MuiTabScrollButton-root.Mui-disabled": {
    color: "rgba(109, 125, 139, .3)",
  },
  "& .MuiTabs-scroller": {
    "& .MuiTabs-flexContainer": {
      justifyContent: "flex-start",
      "& .MuiTab-root": {
        minHeight: "30px",
        width: "max-content",
        maxWidth: "max-content",
        minWidth: "max-content",
        textTransform: "capitalize",
        color: "rgba(109, 125, 139, 1)",
        opacity: "1",
        borderBottom: "0",
        fontSize: "13px",
        padding: ".8rem 1.4rem",
        margin: "0",
      },
      "& .Mui-selected": {
        color: theme.palette.primary.main,
        zIndex: 1,
        "& svg": {
          "& path": {
            stroke: theme.palette.primary.main,
          },
        },
      },
    },
  },
}));

const StyledStack = styled(Stack)(() => ({
  padding: "24px",
  gap: "24px",
  border: "1px solid #EBEBEB",
  background: "#fff",
  borderRadius: "8px",
  height: "100%",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ height: "100%", overflow: "hidden" }} //  //   overflow: "auto"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ITEM_HEIGHT = 54;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

const Reports = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeCptCodeTab, setActiveCptCodeTab] = useState(0);
  const [selectedReports, setSelectedReports] = useState([]);
  const [snackbarData, setSnackbarData] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedMonthValue, setSelectedMonthValue] = useState("this_month");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [cptCodesPage, setCptCodesPage] = useState(0);
  const [cptCodesPageSize, setCptCodesPageSize] = useState(10);
  // const [loadingReport, setLoadingReport] = useState(false);
  const [query, setQuery] = useState("");
  const [agentOptions, setAgentOptions] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [payerValue, setPayerValue] = useState("All");

  const agencyId = Cookies.get("agency_id_vita");

  const changedAgencyId = useSelector((state) => state.changedAgencyId);
  const { changed } = changedAgencyId;

  const dispatch = useDispatch();
  const isBelow1296Screen = useMediaQuery("(max-width:1292px)");

  const getReportsList = useSelector((state) => state.getReportsList);
  const {
    loading: loadingReports,
    error: errorReports,
    reportsList,
  } = getReportsList;

  const downloadAllReport = useSelector((state) => state.downloadAllReport);
  const {
    loading: loadingDownloadAll,
    error: errorDownloadAll,
    downloadedAllReport,
  } = downloadAllReport;

  const getReportStats = useSelector((state) => state.getReportStats);
  const {
    loading: loadingStats,
    error: errorStats,
    reportStats,
  } = getReportStats;

  const getReportCptCodesList = useSelector(
    (state) => state.getReportCptCodesList
  );
  const {
    loading: loadingCptCodes,
    error: errorCptCodes,
    reportCptCodesList,
  } = getReportCptCodesList;

  const getCptCodeSummary = useSelector((state) => state.getCptCodeSummary);
  const { loading: loadingCptSummary } = getCptCodeSummary;

  const getCptTabsCount = useSelector((state) => state.getCptTabsCount);
  const { cptTabsCount } = getCptTabsCount;

  const getUnpaginatedAgentLists = useSelector(
    (state) => state.getUnpaginatedAgentLists
  );
  const { unPaginatedAgents: allAgents } = getUnpaginatedAgentLists;

  const getAllPayers = useSelector((state) => state.getAllPayers);
  const { loading: loadingPayers, allPayers } = getAllPayers;

  const closeSnackbarHandler = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const cardItems = [
    {
      title: "Estimated Bill Amount",
      value: reportStats?.stats?.total_bill_amount || parseInt(0).toFixed(2),
    },
    {
      title: "Total CPT Codes Generated",
      value: reportStats?.stats?.total_cpt_codes_generated || 0,
      shortTitle: "Total CPT Codes Gen.",
    },
    {
      title: "Total Active Patients",
      value: reportStats?.stats?.active_patients_count || 0,
    },
    {
      title: "Total Time Spent",
      value:
        formatElapsedTime(reportStats?.stats?.total_time_spent * 1000) || 0,
    },
  ];

  const allTabs = [
    {
      title: "On Demand",
    },
    {
      title: "Auto generated",
    },
  ];

  const cptCodeTabs = [
    {
      title: "In progress",
      count: cptTabsCount?.in_progress_cpt_codes_count || 0,
    },
    {
      title: "Generated",
      count: cptTabsCount?.completed_cpt_codes_count || 0,
    },
  ];

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedReports([]);
    setPage(0);
    setPageSize(10);
  };

  const cptCodeChangeHandler = (event, newValue) => {
    setActiveCptCodeTab(newValue);

    setCptCodesPage(0);
    setCptCodesPageSize(10);
  };

  useEffect(() => {
    dispatch(getAllPayersFunc());
  }, [agencyId]);

  useEffect(() => {
    const { startDate: startDt, endDate: endDt } =
      getReportDateRanges(selectedMonthValue);
    setStartDate(startDt);
    setEndDate(endDt);

    const statsObj = {
      agency_id: agencyId,
      start_date: startDt,
      end_date: endDt,
      assigned_to: assignedTo,
      payer: payerValue === "All" ? "" : payerValue,
    };

    dispatch(getReportStatsFunc(statsObj));
  }, [selectedMonthValue, assignedTo, payerValue, changed, agencyId]);

  useEffect(() => {
    const { startDate: startDt, endDate: endDt } =
      getReportDateRanges(selectedMonthValue);
    setStartDate(startDt);
    setEndDate(endDt);

    let reportObj;

    if (activeTab === 0) {
      reportObj = {
        agency_id: agencyId,
        page: page + 1,
        report_type: "eoc",
        report_generation_type: "on_demand",
        start_date: startDt,
        end_date: endDt,
        assigned_to: assignedTo,
        payer: payerValue === "All" ? "" : payerValue,
      };
      dispatch(getReportsListFunc(reportObj));
    } else if (activeTab === 1) {
      reportObj = {
        agency_id: agencyId,
        page: page + 1,
        report_type: "eoc",
        report_generation_type: "scheduled",
        start_date: startDt,
        end_date: endDt,
        assigned_to: assignedTo,
        payer: payerValue === "All" ? "" : payerValue,
      };

      dispatch(getReportsListFunc(reportObj));
    }
  }, [
    activeTab,
    page,
    selectedMonthValue,
    assignedTo,
    payerValue,
    changed,
    agencyId,
  ]);

  useEffect(() => {
    const { startDate: startDt, endDate: endDt } =
      getReportDateRanges(selectedMonthValue);
    setStartDate(startDt);
    setEndDate(endDt);

    let reportStatsObj;

    if (activeCptCodeTab === 0) {
      reportStatsObj = {
        agency_id: agencyId,
        page: cptCodesPage + 1,
        report_status: "in_progress",
        start_date: startDt,
        end_date: endDt,
        payer: payerValue === "All" ? "" : payerValue,
        assigned_to: assignedTo,
      };

      dispatch(getReportCptCodesListFunc(reportStatsObj));
    } else if (activeCptCodeTab === 1) {
      reportStatsObj = {
        agency_id: agencyId,
        page: cptCodesPage + 1,
        report_status: "completed",
        start_date: startDt,
        end_date: endDt,
        payer: payerValue === "All" ? "" : payerValue,
        assigned_to: assignedTo,
      };

      dispatch(getReportCptCodesListFunc(reportStatsObj));
    }
  }, [
    activeCptCodeTab,
    cptCodesPage,
    selectedMonthValue,
    assignedTo,
    payerValue,
    changed,
    agencyId,
  ]);

  useEffect(() => {
    const { startDate: startDt, endDate: endDt } =
      getReportDateRanges(selectedMonthValue);
    setStartDate(startDt);
    setEndDate(endDt);

    let summaryObj;

    if (activeCptCodeTab === 0) {
      summaryObj = {
        agency_id: agencyId,
        status: "in_progress",
        start_date: startDt,
        end_date: endDt,
        payer: payerValue === "All" ? "" : payerValue,
        assigned_to: assignedTo,
      };

      dispatch(getCptCodesSummaryFunc(summaryObj));
    } else if (activeCptCodeTab === 1) {
      summaryObj = {
        agency_id: agencyId,
        status: "completed",
        start_date: startDt,
        end_date: endDt,
        payer: payerValue === "All" ? "" : payerValue,
        assigned_to: assignedTo,
      };

      dispatch(getCptCodesSummaryFunc(summaryObj));
    }
  }, [
    activeCptCodeTab,
    selectedMonthValue,
    assignedTo,
    payerValue,
    changed,
    agencyId,
  ]);

  useEffect(() => {
    const { startDate: startDt, endDate: endDt } =
      getReportDateRanges(selectedMonthValue);
    setStartDate(startDt);
    setEndDate(endDt);

    const cptObj = {
      agency_id: agencyId,
      start_date: startDt,
      end_date: endDt,
      payer: payerValue === "All" ? "" : payerValue,
      assigned_to: assignedTo,
    };

    dispatch(getCptTabsCountFunc(cptObj));
  }, [agencyId, payerValue, assignedTo, selectedMonthValue]);

  const lastMonthsDate = dayjs()?.subtract(4, "month")?.format("YYYY-MM-DD");

  const changeMonthFilterHandler = (e) => {
    setSelectedMonthValue(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const cptCodesPageHandler = (event, newPage) => {
    setCptCodesPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const cptCodesRowsPerPageHandler = (event) => {
    setCptCodesPageSize(parseInt(event.target.value, 10));
    setCptCodesPage(0);
  };

  useEffect(() => {
    if (!allAgents) {
      dispatch(getUnpaginatedAgentsListFunc());
    }
  }, [allAgents]);

  useEffect(() => {
    if (allAgents?.ok) {
      const agentOptions =
        allAgents?.agents_list?.length > 0
          ? allAgents?.agents_list?.map((item) => ({
              title: item?.name,
              value: item?.id,
            }))
          : [];
      setAgentOptions(agentOptions);
    }
  }, [allAgents]);

  const changeAssignedToHandler = (event) => {
    const {
      target: { value },
    } = event;

    setAssignedTo(typeof value === "string" ? value.split(",") : value);
  };

  const downloadAllReportHandler = () => {
    let reportObj;
    if (activeTab === 0) {
      reportObj = {
        agency_id: agencyId,
        report_type: "eoc",
        report_generation_type: "on_demand",
        start_date: startDate,
        end_date: endDate,
        assigned_to: assignedTo,
        payer: payerValue === "All" ? "" : payerValue,
      };

      dispatch(downloadAllReportFunc(reportObj));
    } else if (activeTab === 1) {
      reportObj = {
        agency_id: agencyId,
        report_type: "eoc",
        report_generation_type: "scheduled",
        start_date: startDate,
        end_date: endDate,
        assigned_to: assignedTo,
        payer: payerValue === "All" ? "" : payerValue,
      };
      dispatch(downloadAllReportFunc(reportObj));
    }
  };

  useEffect(() => {
    if (downloadedAllReport?.ok) {
      window.open(downloadedAllReport?.download_url, "_blank");
      dispatch(resetDownloadAll());
      // setLoadingReport(true);
      // fetch(downloadedAllReport?.download_url)
      //   .then((res) => res.blob())
      //   .then((blob) => {
      //     const url = window.URL.createObjectURL(blob);
      //     const link = document.createElement("a");
      //     link.href = url;

      //     link.download = `EOC - ${practiceName}`;
      //     document.body.appendChild(link);
      //     link.click();
      //     document.body.removeChild(link);
      //     window.URL.revokeObjectURL(url);
      //     setLoadingReport(false);
      //     dispatch(resetDownloadAll());
      //   })
      //   .catch((err) => {
      //     setLoadingReport(false);
      //     console.error("Failed to download report", err);
      //     dispatch(resetDownloadAll());
      //   });
    }
  }, [downloadedAllReport]);

  return (
    <>
      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          onClose={closeSnackbarHandler}
          anchorOrigin={{
            vertical: snackbarData?.vertical || "bottom",
            horizontal: snackbarData?.horizontal || "right",
          }}
        >
          <Alert
            onClose={closeSnackbarHandler}
            severity={snackbarData.severity}
            color={snackbarData.color}
            sx={{ width: "100%" }}
          >
            {snackbarData.textContent}
          </Alert>
        </Snackbar>
      )}

      {/* AUTO GEN AND ON DEMAND SECTION */}
      <Stack
        padding="20px 40px"
        paddingBottom="40px"
        height="100%"
        gap="24px"
        // overflow="hidden"
      >
        <Typography variant="h1" fontSize="30px" fontWeight={500}>
          Reports & Insights
        </Typography>
        {/* SELECT FILTERS */}
        <Box display="flex" alignItems="center" gap="16px">
          <CustomSelectFilter
            menuItems={reportMonthFilterOptions(lastMonthsDate, false, true)}
            fontSize="14px"
            label="Duration"
            changeSelectValueHandler={changeMonthFilterHandler}
            selectValue={selectedMonthValue}
            id="export-report-month"
            minWidth={254}
            size="small"
          />
          {/* <CustomSelectFilter
            menuItems={payerOptions}
            changeSelectValueHandler={(e) => {
              setPayerValue(e.target.value);
            }}
            selectValue={payerValue}
            id="assigned-to"
            
            size="small"
            label="Payer"
            MenuProps={MenuProps}
          /> */}
          <Autocomplete
            sx={{
              minWidth: "254px",
              "& .MuiAutocomplete-input": {
                textTransform: "capitalize",
              },
            }}
            options={["All", ...(allPayers?.payers || [])]}
            value={payerValue || "All"}
            onChange={(_, newValue) => setPayerValue(newValue)}
            disableClearable
            size="small"
            loading={loadingPayers}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingPayers ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                label="Payer"
                // margin="dense"
                variant="outlined"
                fullWidth
              />
            )}
          />
          <CustomSelectFilter
            menuItems={agentOptions}
            changeSelectValueHandler={changeAssignedToHandler}
            selectValue={assignedTo}
            id="assigned-to"
            minWidth={254}
            size="small"
            multiple={true}
            label="Assigned to"
            MenuProps={MenuProps}
          />
        </Box>

        <Typography
          variant="body1"
          color="rgba(0, 0, 0, 0.38)"
          fontWeight={500}
        >
          Showing data for{" "}
          <Box component="span" color="#0D0D0D">
            {reportStats?.stats?.active_patients_count || 0}
          </Box>{" "}
          {reportStats?.stats?.active_patients_count === 1
            ? "Patient"
            : "Patients"}
        </Typography>

        <Stack gap="18px">
          {/* CPT CODES SECTION */}
          <Grid container spacing={2}>
            {cardItems?.map((item, index) => (
              <Grid item xs={3} key={index}>
                <StyledStack>
                  <Typography variant="body2" fontWeight={500}>
                    {isBelow1296Screen && item.shortTitle
                      ? item.shortTitle
                      : item?.title}
                  </Typography>
                  {loadingStats ? (
                    <RectangleSkeletons totalRows={1} height={43} />
                  ) : (
                    <Typography
                      variant="body2"
                      fontSize="30px"
                      color="secondary"
                    >
                      {index === 0 && "$"}
                      {item?.value}
                    </Typography>
                  )}
                </StyledStack>
              </Grid>
            ))}
          </Grid>

          <Box
            display="flex"
            alignItems="flex-start"
            gap="18px"
            pb="20px"
            height="100%"
            width="100%"
          >
            {/* GENERATED AND IN PROGRESS CPT CODES SECTION */}
            <Stack
              border="1px solid #EBEBEB"
              bgcolor="#FFF"
              borderRadius="8px"
              width="50%"
              height="618px"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="8px"
                padding="24px 24px 6px"
              >
                <Typography
                  variant="body1"
                  color="secondary"
                  fontWeight={500}
                  textTransform="uppercase"
                >
                  CPT Codes
                </Typography>
              </Box>
              <Stack padding="24px 24px 0">
                <StyledTabs
                  value={activeCptCodeTab}
                  onChange={cptCodeChangeHandler}
                  aria-label="cpt codes"
                >
                  {cptCodeTabs?.map(
                    (tab, key) =>
                      tab.title && (
                        <Tab
                          iconPosition="start"
                          label={
                            <Box display="flex" alignItems="center">
                              <Typography
                                component="span"
                                variant="body1"
                                fontWeight={500}
                                sx={{ textTransform: "capitalize" }}
                              >
                                {tab.title}
                              </Typography>

                              <Typography variant="body1" fontWeight="500">
                                ({tab.count})
                              </Typography>
                            </Box>
                          }
                          key={tab.title}
                          {...a11yProps(key++)}
                        />
                      )
                  )}
                </StyledTabs>
              </Stack>

              {/* CPT SUMMARY BOX */}
              {cptTabsCount?.in_progress_cpt_codes_count ||
              cptTabsCount?.completed_cpt_codes_count ||
              cptTabsCount === undefined ||
              loadingCptSummary ? (
                <CptSummaryBox />
              ) : (
                <></>
              )}

              <TabPanel value={activeCptCodeTab} index={activeCptCodeTab}>
                <InProgressCptCode activeTab={activeCptCodeTab} />
              </TabPanel>
              {!loadingCptCodes && reportCptCodesList?.cpt_codes?.length ? (
                <Box>
                  <TablePagination
                    component="div"
                    count={reportCptCodesList?.count || 0}
                    page={cptCodesPage}
                    onPageChange={cptCodesPageHandler}
                    rowsPerPage={cptCodesPageSize}
                    onRowsPerPageChange={cptCodesRowsPerPageHandler}
                    rowsPerPageOptions={[10]}
                  />
                </Box>
              ) : (
                <></>
              )}
            </Stack>

            <Stack
              border="1px solid #EBEBEB"
              bgcolor="#FFF"
              borderRadius="8px"
              width="50%"
              height="618px"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="8px"
                padding="24px 24px 6px"
              >
                <Box display="flex" alignItems="center" height="35px">
                  <Typography
                    variant="body1"
                    color="secondary"
                    fontWeight={500}
                    textTransform="uppercase"
                  >
                    Evidence of Care
                  </Typography>
                </Box>
                {reportsList?.reports?.length ? (
                  <Box display="flex" alignItems="center" gap="8px">
                    <LoadingButton
                      loading={loadingDownloadAll}
                      variant="contained"
                      onClick={downloadAllReportHandler}
                    >
                      Download All
                    </LoadingButton>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
              <Stack padding="24px 24px 0">
                <StyledTabs
                  value={activeTab}
                  onChange={handleChange}
                  aria-label="evidence of care"
                >
                  {allTabs?.map(
                    (tab, key) =>
                      tab.title && (
                        <Tab
                          iconPosition="start"
                          label={tab.title}
                          key={tab.title}
                          {...a11yProps(key++)}
                        />
                      )
                  )}
                </StyledTabs>
              </Stack>
              {selectedReports?.length ? (
                <Box
                  display="flex"
                  alignItems="center"
                  p="16px 24px 0"
                  justifyContent="space-between"
                  gap="8px"
                >
                  <Box display="flex" gap="4px" alignItems="center">
                    <Typography variant="body2" color="#0D0D0D">
                      {selectedReports?.length}{" "}
                      <Box component="span" color="rgba(0, 0, 0, 0.38)">
                        {" "}
                        {selectedReports?.length === 1 ? "Item" : "Items"}{" "}
                        Selected
                      </Box>
                    </Typography>
                    <IconButton
                      sx={{ padding: 0, fontSize: 14 }}
                      onClick={() => setSelectedReports([])}
                    >
                      <CancelOutlined fontSize="14px" />
                    </IconButton>
                  </Box>

                  <DownloadSelectedReportButton
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarData={setSnackbarData}
                    selectedReports={selectedReports}
                    activeTab={activeTab}
                  />
                </Box>
              ) : (
                <></>
              )}
              <TabPanel value={activeTab} index={activeTab}>
                <AutoGeneratedEOC
                  selectedReports={selectedReports}
                  setSelectedReports={setSelectedReports}
                  activeTab={activeTab}
                  startDate={startDate}
                  endDate={endDate}
                  page={page}
                  assignedTo={assignedTo}
                  payerValue={payerValue}
                />
              </TabPanel>
              {!loadingReports && reportsList?.reports?.length ? (
                <Box>
                  <TablePagination
                    component="div"
                    count={reportsList?.count || 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10]}
                  />
                </Box>
              ) : (
                <></>
              )}
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default Reports;
