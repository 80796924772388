import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarIcon from "../../icons/CalendarIcon";
import CustomSelectFilter from "../../common/CustomSelectFilter";
import dayjs from "dayjs";
import { calculateElapsedTime, logValueOptions } from "../../utils/helpers";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";

const AddTimeDrawerContent = ({
  onClose,
  timeDetails,
  patientDetail,
  onSubmitEditTime,
  onSubmitAddTimeEntry,
}) => {
  const [startTimeValue, setStartTimeValue] = useState(null);
  const [endTimeValue, setEndTimeValue] = useState(dayjs());
  const [dateValue, setDateValue] = useState(null);
  const [selectValue, setSelectValue] = useState("");
  const [notesValue, setNotesValue] = useState("");
  const [changedTime, setChangedTime] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [communicationInteraction, setCommunicationInteraction] =
    useState(false);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [openStartTimePicker, setOpenStartTimePicker] = useState(false);
  const [openEndTimePicker, setOpenEndTimePicker] = useState(false);
  const [endTimeError, setEndTimeError] = useState(false);

  const agencyId = Cookies.get("agency_id_vita");
  const dispatch = useDispatch();

  const updateTimeEntry = useSelector((state) => state.updateTimeEntry);
  const { loading: loadingUpdateTimeEntry } = updateTimeEntry;

  const createTimeEntry = useSelector((state) => state.createTimeEntry);
  const { loading: loadingCreateTimeEntry } = createTimeEntry;

  const currentMonth = dayjs().month();
  const currentYear = dayjs().year();

  useEffect(() => {
    setSelectValue("Therapeutic Data Review");
  }, []);

  useEffect(() => {
    if (timeDetails?.id) {
      setStartTimeValue(dayjs(timeDetails?.start_time));
      setEndTimeValue(dayjs(timeDetails?.end_time));
      setDateValue(dayjs(timeDetails?.date));
      setNotesValue(timeDetails?.notes);
      setSelectValue(timeDetails?.category);
      setElapsedTime(timeDetails?.time_in_seconds);
      setCommunicationInteraction(timeDetails?.contains_interaction);
    } else {
      setDateValue(dayjs(timeDetails));
    }
  }, []);

  const saveTimeHandler = () => {
    if (endTimeValue && startTimeValue) {
      const fixedDate = "1970-01-01";
      const normalizedStartTime = dayjs(
        `${fixedDate} ${startTimeValue.format("HH:mm")}`
      );
      const normalizedEndTime = dayjs(
        `${fixedDate} ${endTimeValue.format("HH:mm")}`
      );

      if (normalizedEndTime.isBefore(normalizedStartTime)) {
        setEndTimeError(true);
        return;
      }
    }

    setEndTimeError(false);

    if (timeDetails?.id) {
      const fixedDate = "1970-01-01";
      const start = dayjs(
        `${fixedDate} ${dayjs(startTimeValue).format("HH:mm")}`
      )
        .set("second", 0)
        .set("millisecond", 0);
      const end = dayjs(`${fixedDate} ${dayjs(endTimeValue).format("HH:mm")}`)
        .set("second", 0)
        .set("millisecond", 0);

      const timeEntryObj = {
        time_entry_id: timeDetails?.id,
        agency_id: agencyId,
        date: dateValue?.utcOffset(0, true),
        start_time: changedTime
          ? dayjs(startTimeValue)?.set("second", 0).set("millisecond", 0)
          : startTimeValue,
        end_time: changedTime
          ? dayjs(endTimeValue)?.set("second", 0).set("millisecond", 0)
          : endTimeValue,
        time_in_seconds: changedTime
          ? calculateElapsedTime(start, end)
          : elapsedTime,
        category: selectValue,
        notes: notesValue,
        manually_added: true,
        contains_interaction: communicationInteraction,
      };

      onSubmitEditTime(timeEntryObj);
    } else {
      const timeEntryObj = {
        patient_id: patientDetail?.patient?.id,
        agency_id: agencyId,
        date: dateValue?.utcOffset(0, true),
        start_time: startTimeValue,
        end_time: endTimeValue,
        time_in_seconds: calculateElapsedTime(startTimeValue, endTimeValue),
        category: selectValue,
        notes: notesValue,
        manually_added: true,
        contains_interaction: communicationInteraction,
      };

      onSubmitAddTimeEntry(timeEntryObj);
    }
  };

  const closeDrawerHandler = () => {
    setEndTimeError(false);

    onClose();
  };

  const disbalePreviousDateHandler = (date) => {
    const selectedYear = dayjs(date)?.year();
    const selectedMonth = dayjs(date)?.month();

    if (selectedYear < currentYear) {
      return true;
    }

    if (selectedYear === currentYear && selectedMonth < currentMonth) {
      return true;
    }

    return false;
  };

  return (
    <>
      {" "}
      <Stack flex="1" sx={{ overflowY: "auto" }} gap="24px" height="100%">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
          paddingBottom="32px"
        >
          <Typography variant="h5">
            {timeDetails?.id ? "Edit Time Log" : "Log Time"}
          </Typography>
          <IconButton size="small" onClick={closeDrawerHandler}>
            <CancelOutlinedIcon />
          </IconButton>
        </Box>

        <Stack gap="21px">
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            gap="24px"
            width="100%"
          >
            <Box sx={{ width: "50%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Start Time"
                  value={startTimeValue}
                  onChange={(newValue) => {
                    setStartTimeValue(newValue);
                    setChangedTime(true);
                  }}
                  onOpen={() => setOpenStartTimePicker(true)}
                  onClose={() => setOpenStartTimePicker(false)}
                  open={openStartTimePicker}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: false,
                      onClick: () => setOpenStartTimePicker(true),
                    },
                  }}
                  timeSteps={{ minutes: 1 }}

                  // slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="End Time"
                  value={endTimeValue}
                  onChange={(newValue) => {
                    setEndTimeError(false);
                    setEndTimeValue(newValue);
                    setChangedTime(true);
                  }}
                  onOpen={() => setOpenEndTimePicker(true)}
                  onClose={() => setOpenEndTimePicker(false)}
                  open={openEndTimePicker}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: false,
                      onClick: () => setOpenEndTimePicker(true),
                      error: endTimeError,
                      helperText: endTimeError
                        ? "End time cannot be earlier than start time."
                        : "",
                      sx: endTimeError
                        ? {
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#D32F2F",
                              },
                            },
                            "& .MuiFormLabel-root.Mui-error": {
                              color: "#D32F2F",
                            },
                            "& .MuiFormHelperText-root.Mui-error": {
                              color: "#D32F2F",
                            },
                          }
                        : {},
                    },
                  }}
                  timeSteps={{ minutes: 1 }}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={dateValue}
              label="Date"
              disableFuture
              onChange={(newValue) => setDateValue(newValue)}
              slots={{ openPickerIcon: CalendarIcon }}
              onOpen={() => setIsPickerOpen(true)}
              onClose={() => setIsPickerOpen(false)}
              shouldDisableDate={disbalePreviousDateHandler}
              open={isPickerOpen}
              slotProps={{
                textField: {
                  // fullWidth: true,
                  error: false,
                  onClick: () => setIsPickerOpen(true),
                },
              }}
            />
          </LocalizationProvider>

          <CustomSelectFilter
            menuItems={logValueOptions}
            changeSelectValueHandler={(e) => setSelectValue(e.target.value)}
            selectValue={selectValue}
            id="log_type"
            label="Log Type"
            minWidth="fullWidth"
            size="medium"
          />

          <FormControlLabel
            sx={{ marginRight: "0", flex: "1" }}
            control={
              <Checkbox
                checked={communicationInteraction}
                onChange={(e) => setCommunicationInteraction(e.target.checked)}
              />
            }
            label="Interacted with the patient in this session."
          />

          <TextField
            multiline
            rows={6}
            label="Notes"
            value={notesValue}
            onChange={(e) => setNotesValue(e.target.value)}
          />
        </Stack>
      </Stack>
      <Stack alignItems="flex-end" pt={1.4}>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={saveTimeHandler}
          loading={loadingUpdateTimeEntry || loadingCreateTimeEntry}
          disabled={!startTimeValue || !endTimeValue || !selectValue}
          // disabled={!firstName || !email || emailError}
          // height="40px"
          // width="140px"
        >
          {timeDetails?.id ? "Save Changes" : "Save"}
        </LoadingButton>
      </Stack>{" "}
    </>
  );
};

export default AddTimeDrawerContent;
