import {
  DOWNLOAD_ALL_REPORT_FAIL,
  DOWNLOAD_ALL_REPORT_REQUEST,
  DOWNLOAD_ALL_REPORT_SUCCESS,
  GET_ALL_PAYERS_FAIL,
  GET_ALL_PAYERS_REQUEST,
  GET_ALL_PAYERS_SUCCESS,
  GET_CPT_CODES_SUMMARY_FAIL,
  GET_CPT_CODES_SUMMARY_REQUEST,
  GET_CPT_CODES_SUMMARY_SUCCESS,
  GET_CPT_TABS_COUNT_FAIL,
  GET_CPT_TABS_COUNT_REQUEST,
  GET_CPT_TABS_COUNT_SUCCESS,
  GET_REPORT_CPT_CODES_LIST_FAIL,
  GET_REPORT_CPT_CODES_LIST_REQUEST,
  GET_REPORT_CPT_CODES_LIST_SUCCESS,
  GET_REPORT_STATS_FAIL,
  GET_REPORT_STATS_REQUEST,
  GET_REPORT_STATS_SUCCESS,
  GET_REPORTS_LIST_FAIL,
  GET_REPORTS_LIST_REQUEST,
  GET_REPORTS_LIST_SUCCESS,
  RESET_DOWNLOAD_ALL_REPORT,
  RESET_RETRY_EOC_REPORT,
  RETRY_EOC_REPORT_FAIL,
  RETRY_EOC_REPORT_REQUEST,
  RETRY_EOC_REPORT_SUCCESS,
} from "../../assets/constants/reportsConstants";

// GET REPORTS LIST REDUCER
export const getReportsListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_REPORTS_LIST_REQUEST:
      return { loading: true };
    case GET_REPORTS_LIST_SUCCESS:
      return { loading: false, reportsList: action.payload };
    case GET_REPORTS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// DOWNLOAD ALL REPORTS REDUCER
export const downloadAllReportReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_ALL_REPORT_REQUEST:
      return { loading: true };
    case DOWNLOAD_ALL_REPORT_SUCCESS:
      return { loading: false, downloadedAllReport: action.payload };
    case DOWNLOAD_ALL_REPORT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_DOWNLOAD_ALL_REPORT:
      return {};
    default:
      return state;
  }
};

// GET REPORT STATS REDUCER
export const getReportStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_REPORT_STATS_REQUEST:
      return { loading: true };
    case GET_REPORT_STATS_SUCCESS:
      return { loading: false, reportStats: action.payload };
    case GET_REPORT_STATS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET ALL PAYERS REDUCER
export const getAllPayersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_PAYERS_REQUEST:
      return { loading: true };
    case GET_ALL_PAYERS_SUCCESS:
      return { loading: false, allPayers: action.payload };
    case GET_ALL_PAYERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// RETRY EOC REPORT REDUCER
export const retryEocReportReducer = (state = {}, action) => {
  switch (action.type) {
    case RETRY_EOC_REPORT_REQUEST:
      return { loading: true };
    case RETRY_EOC_REPORT_SUCCESS:
      return { loading: false, retriedReport: action.payload };
    case RETRY_EOC_REPORT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_RETRY_EOC_REPORT:
      return {};
    default:
      return state;
  }
};

// GET CPT TABS COUNT REDUCER
export const getCptTabsCountReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CPT_TABS_COUNT_REQUEST:
      return { loading: true };
    case GET_CPT_TABS_COUNT_SUCCESS:
      return { loading: false, cptTabsCount: action.payload };
    case GET_CPT_TABS_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET CPT CODES SUMMARY REDUCER
export const getCptCodesSummaryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CPT_CODES_SUMMARY_REQUEST:
      return { loading: true };
    case GET_CPT_CODES_SUMMARY_SUCCESS:
      return { loading: false, cptCodeSummary: action.payload };
    case GET_CPT_CODES_SUMMARY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET REPORT CPT CODES LIST REDUCER
export const getReportCptCodesListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_REPORT_CPT_CODES_LIST_REQUEST:
      return { loading: true };
    case GET_REPORT_CPT_CODES_LIST_SUCCESS:
      return { loading: false, reportCptCodesList: action.payload };
    case GET_REPORT_CPT_CODES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
