import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import bellIcon from "../../assets/images/bell-img2.png";
import DashboardCard from "../DashboardCard";
import FolderImg from "../../assets/images/folder-img.png";
import PaperImg from "../../assets/images/paper-img.png";
import NetworkImg from "../../assets/images/network-img.png";
import { InfoCircle, RiskCaseEmptyState } from "../../assets/constants";
import NewMessageCard from "../NewMessageCard";
import RiskCasesCard from "../RiskCasesCard";
import EmptyBox from "../../assets/images/empty-box.png";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardAlertsFunc,
  getDashboardAnalyticsFunc,
  getHighRiskCasesFunc,
  getUnreadChatMessagesFunc,
} from "../../actions/Cases";
import { getAgencyDetailFunc } from "../../actions/Agency";
import CircularProgressLoading from "../../common/CircularProgress";
import AlertMessage from "../../common/AlertMessage";
import { dateTimeFormat, timeDateFormat } from "../../utils/helpers";
import { segmentTrack } from "../../utils/helpers/tracking_utils";
import UserFeedCard from "../UserFeedCard";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getWebNotificationsFunc,
  userFeedNotificationsFunc,
} from "../../actions/AppConfig";
import Cookies from "js-cookie";
import SkeletonGrid from "../SkeletonGrid";
import RectangleSkeletons from "../RectangleSkeletons";
import dashboardImage from "../../assets/images/dashboard-img.png";
import CustomSelectFilter from "../../common/CustomSelectFilter";
import PatientVector from "../../icons/PatientVector";
import PendingEnrollVector from "../../icons/PendingEnrollVector";
import InactivePatientVector from "../../icons/InactivePatientVector";
import AttentionCard from "../AttentionCard";
import CaughtUpImage from "../../assets/images/caughtupImage.png";

const page_size = 10;

const DashboardComponent = () => {
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [selectedView, setSelectedView] = useState("dashboard");
  const [userFeedNotifications, setUserFeedNotifications] = useState([]);
  const [renderUserFeed, setRenderUserFeed] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const dispatch = useDispatch();

  const changedAgencyId = useSelector((state) => state.changedAgencyId);
  const { changed } = changedAgencyId;

  const agentProfile = useSelector((state) => state.agentProfile);
  const {
    loading: loadingAgentProfile,
    error: errorAgentProfile,
    agentUser,
  } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const agencyId = Cookies.get("agency_id_vita");

  const dashboardAnalytics = useSelector((state) => state.dashboardAnalytics);
  const { loading, error, dashboardAnalytic } = dashboardAnalytics;

  const dashboardAlerts = useSelector((state) => state.dashboardAlerts);
  const {
    loading: loadingAlerts,
    error: errorAlerts,
    dashboardAlert,
  } = dashboardAlerts;

  const atHighRiskCases = useSelector((state) => state.atHighRiskCases);
  const {
    loading: loadingHighRiskCases,
    error: errorHighRiskcases,
    highRiskCases,
  } = atHighRiskCases;

  const getUnreadChatMessages = useSelector(
    (state) => state.getUnreadChatMessages
  );
  const {
    loading: loadingUnreadChatMessages,
    error: errorUnreadChatMessages,
    unreadChatMessages,
  } = getUnreadChatMessages;

  // USER FEED NOTIFICATIONS
  const getUserFeedNotifications = useSelector(
    (state) => state.getUserFeedNotifications
  );
  const {
    loading: loadingUserFeeds,
    error: errorUserFeeds,
    userFeeds,
  } = getUserFeedNotifications;

  const changeViewHandler = (e) => {
    setSelectedView(e.target.value);
  };

  useEffect(() => {
    // dispatch(getHighRiskCasesFunc());
    if (!agencyDetail) {
      dispatch(getAgencyDetailFunc());
    }
    // dispatch(userFeedNotificationsFunc(page, page_size));
  }, [agencyId, changed]);

  useEffect(() => {
    if (agentUser?.agent?.id) {
      if (selectedView === "dashboard") {
        dispatch(getDashboardAnalyticsFunc(agentUser?.agent?.id));
        dispatch(getDashboardAlertsFunc(agentUser?.agent?.id));
        dispatch(getUnreadChatMessagesFunc(agentUser?.agent?.id));
      } else {
        dispatch(getDashboardAnalyticsFunc("all"));
        dispatch(getDashboardAlertsFunc("all"));
        dispatch(getUnreadChatMessagesFunc("all"));
      }
    }
  }, [agentUser?.agent?.id, selectedView, agencyId, agencyDetail, changed]);

  const fetchMoreData = () => {
    if (hasNext) {
      const nextPage = page + 1;

      dispatch(userFeedNotificationsFunc(nextPage, page_size));

      setPage(nextPage);
    }
  };

  useEffect(() => {
    if (!loadingUserFeeds && userFeeds?.ok) {
      setUserFeedNotifications((prevState) => [
        ...prevState,
        ...userFeeds?.user_feed,
      ]);
      setHasNext(userFeeds?.has_next);
    }
  }, [userFeeds]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // setRenderUserFeed(false);
      setPage(1);
      // setUserFeedNotifications([]);
      // dispatch(userFeedNotificationsFunc(page, page_size));
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "dashboard",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  return (
    // <Stack
    //   sx={{
    //     background: "linear-gradient(180deg, #FCFCFC 0.03%, #E9F4FF 99.97%)",
    //   }}
    //   height="100%"
    //   alignItems="center"
    //   justifyContent="center"
    //   gap="19px"
    //   position="relative"
    //   overflow="hidden"
    // >
    //   <Box width="560px" height="439px">
    //     <img
    //       width="100%"
    //       height="100%"
    //       src={dashboardImage}
    //       alt="coming soon"
    //     />
    //   </Box>
    //   <Stack gap="8px" alignItems="center" width="646px">
    //     <Typography variant="h3" fontSize="44px" color="#212121">
    //       All in one place. Stay tuned!
    //     </Typography>
    //     <Typography
    //       variant="body1"
    //       color="secondary"
    //       fontSize="20px"
    //       textAlign="center"
    //     >
    //       A seamless way to track your patients, manage CPT codes, and
    //       streamline billing.
    //     </Typography>
    //   </Stack>
    //   <Stack
    //     position="absolute"
    //     bgcolor="#0C80F3"
    //     width="442px"
    //     bottom="61px"
    //     right="-125px"
    //     boxShadow="15px 8px 13px 0px rgba(0, 0, 0, 0.10)"
    //     padding="8px 0"
    //     textAlign="center"
    //     sx={{ transform: "rotate(-40.193deg)" }}
    //   >
    //     <Typography color="#fff" fontSize="29px" variant="body1">
    //       Coming Soon
    //     </Typography>
    //   </Stack>
    // </Stack>
    <>
      {/* {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )} */}
      {!loadingAgentProfile && errorAgentProfile && (
        <AlertMessage
          message={errorAgentProfile}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}
      {/* {!loadingHighRiskCases && errorHighRiskcases && (
        <AlertMessage
          message={errorHighRiskcases}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )} */}
      {!loadingUnreadChatMessages && errorUnreadChatMessages && (
        <AlertMessage
          message={errorUnreadChatMessages}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      <Stack flexDirection="column" gap="28px" padding="20px 37px">
        {/* 1st ROW DASHBOARD MAIN*/}

        {/* 1st BOX WELCOME */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          width="100%"
        >
          {loadingAgentProfile ? (
            <Stack gap="17px">
              <Skeleton variant="rounded" width={230} height={23} />
              <Skeleton variant="rounded" width={395} height={42} />
            </Stack>
          ) : (
            <Stack maxWidth="399px" gap="2px">
              <Typography
                id="welcome-text"
                color="#0C3D6D"
                // fontSize="28px"
                variant="h5"
                fontWeight="500"
              >
                Hello, {agentUser?.agent?.name}
              </Typography>
              <Typography variant="body1" color="rgba(0, 0, 0, 0.38)">
                Track patient progress and stay updated with real-time alerts
                and messages.
              </Typography>
            </Stack>
          )}

          <CustomSelectFilter
            menuItems={[
              { title: "My dashboard", value: "dashboard" },
              { title: "All", value: "all" },
            ]}
            changeSelectValueHandler={changeViewHandler}
            selectValue={selectedView}
            id="dashboard-view"
            minWidth={177}
            size="small"
          />
        </Box>

        {/* 2nd BOX USER FEED */}
        {/* <Stack
            boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
            padding="24px"
            borderRadius="8px"
            maxWidth="347px"
            width="100%"
            sx={{ flexGrow: "1" }}
          >
            <Stack gap="16px" height="418px" sx={{ flexGrow: 1 }}>
              <Box
                display="flex"
                justifyContent="flex-start"
                gap="4px"
                alignItems="flex-start"
              >
                <Typography
                  variant="h2"
                  fontSize="16px"
                  fontWeight="500"
                  color="secondary"
                  textTransform="uppercase"
                  lineHeight="28px"
                >
                  User Feed
                </Typography>
                <Tooltip
                  placement="top"
                  arrow
                  PopperProps={{
                    sx: {
                      marginBottom: "-7px !important",
                    },
                  }}
                >
                  <InfoCircle />
                </Tooltip>
              </Box>
              {loadingUserFeeds && userFeedNotifications?.length === 0 ? (
                <RectangleSkeletons height={67} />
              ) : userFeedNotifications?.length > 0 ? (
                <div
                  id="scrollableDiv"
                  style={{ overflow: "auto", height: "400px" }}
                >
                  <InfiniteScroll
                    dataLength={userFeedNotifications.length || []}
                    next={fetchMoreData}
                    hasMore={hasNext}
                    loader={
                      <CircularProgressLoading size={20} overflow="hidden" />
                    }
                    scrollableTarget="scrollableDiv"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    {userFeedNotifications?.map((data, index) => (
                      <UserFeedCard data={data} key={index} />
                    ))}
                  </InfiniteScroll>
                </div>
              ) : (
                <Stack
                  gap="16px"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Box width="128px" height="128px">
                    <img src={EmptyBox} alt="empty box" width="100%" />
                  </Box>
                  <Typography variant="body1">No user feeds yet</Typography>
                </Stack>
              )}
            </Stack>
            <Box
                position="absolute"
                top="-23px"
                right="-23px"
                width="215px"
                sx={{ transform: "rotate(17.166deg)" }}
              >
                <img src={bellIcon} alt="notification" width="100%" />
              </Box>

          <Stack
                alignItems="flex-start"
                justifyContent="flex-end"
                gap="24px"
              >

                <Typography variant="body1" fontSize="18px">
                  Instant Case Updates Through Push Notifications!
                </Typography>
                <Button
                  href="/marketing?tab=push_notifications"
                  variant="contained"
                  size="large"
                >
                  Set up push notifications
                </Button>
              </Stack>
          </Stack> */}

        {/* 2nd ROW CARDS CASES */}
        <Box
          display="flex"
          alignItems="flex-start"
          width="100%"
          justifyContent="space-between"
          gap="25px"
        >
          <Box display="flex" alignItems="center" gap="18px" width="100%">
            <DashboardCard
              headerText="My Patients"
              number={dashboardAnalytic?.metrics?.enrolled_patients_count}
              imgName={<PatientVector />}
              loading={loading || loadingAgentProfile}
            />
            <DashboardCard
              headerText="Pending Enrollment"
              number={
                dashboardAnalytic?.metrics?.pending_enrollment_patients_count
              }
              imgName={<PendingEnrollVector />}
              loading={loading || loadingAgentProfile}
            />
            <DashboardCard
              headerText="Inactive Patients"
              number={dashboardAnalytic?.metrics?.inactive_patients_count}
              imgName={<InactivePatientVector />}
              // borderRadius={true}
              loading={loading || loadingAgentProfile}
            />
          </Box>

          <Stack
            gap="19px"
            maxWidth="347px"
            width="100%"
            sx={{ flexGrow: "1" }}
          >
            {/* 1st BOX UNREAD MESSAGES CARD */}
            <Stack borderRadius="8px" border="1px solid #EBEBEB" height="462px">
              <Stack height="418px" sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h2"
                  fontSize="18px"
                  fontWeight="500"
                  color="#0D0D0D"
                  textTransform="capitialize"
                  lineHeight="28px"
                  padding="20px 16px"
                >
                  Unread Messages
                </Typography>

                <Stack
                  gap="8px"
                  overflow="auto"
                  padding="0 16px"
                  paddingBottom="20px"
                  height="100%"
                >
                  {loadingUnreadChatMessages ||
                  loadingAgency ||
                  loadingAgentProfile ? (
                    <RectangleSkeletons totalRows={3} height={64} />
                  ) : unreadChatMessages?.unreadMessages?.length > 0 ? (
                    <Stack gap="8px" overflow="auto">
                      {unreadChatMessages?.unreadMessages?.map((message, i) => (
                        <NewMessageCard
                          key={i}
                          senderProfile={message?.patient?.profile_picture_url}
                          senderName={message?.senderDisplayName}
                          chatDate={dateTimeFormat(message?.createdOn)}
                          messageContent={message?.content}
                          patient_id={message?.patient?.id}
                          avatarColor={message?.patient?.avatar_color}
                        />
                      ))}
                    </Stack>
                  ) : (
                    <Stack
                      gap="8px"
                      alignItems="center"
                      justifyContent="center"
                      height="100%"
                    >
                      <Box width="100px">
                        <img
                          width="100%"
                          height="100%"
                          src={CaughtUpImage}
                          alt="no alerts"
                        />
                      </Box>
                      <Typography variant="body2">
                        You are all caught up!
                      </Typography>
                    </Stack>
                  )}
                </Stack>

                {/* NEED YOUR ATTENTION CARD */}
                {/* {loadingUserFeeds && userFeedNotifications?.length === 0 ? (
                <RectangleSkeletons height={67} />
              ) : userFeedNotifications?.length > 0 ? (
                <div
                  id="scrollableDiv"
                  style={{ overflow: "auto", height: "400px" }}
                >
                  <InfiniteScroll
                    dataLength={userFeedNotifications.length || []}
                    next={fetchMoreData}
                    hasMore={hasNext}
                    loader={
                      <CircularProgressLoading size={20} overflow="hidden" />
                    }
                    scrollableTarget="scrollableDiv"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    {userFeedNotifications?.map((data, index) => (
                      <UserFeedCard data={data} key={index} />
                    ))}
                  </InfiniteScroll>
                </div>
              ) : (
                <Stack
                  gap="16px"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Box width="128px" height="128px">
                    <img src={EmptyBox} alt="empty box" width="100%" />
                  </Box>
                  <Typography variant="body1">No user feeds yet</Typography>
                </Stack>
              )} */}
              </Stack>
            </Stack>

            {/* 2nd BOX ATTENTION CARD */}
            <Stack
              // boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
              borderRadius="8px"
              border="1px solid #EBEBEB"
              height="462px"
            >
              <Stack height="418px" sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h2"
                  fontSize="18px"
                  fontWeight="500"
                  color="#0D0D0D"
                  textTransform="capitialize"
                  lineHeight="28px"
                  padding="20px 16px"
                >
                  Need your attention
                </Typography>

                <Stack
                  gap="8px"
                  overflow="auto"
                  padding="0 16px"
                  paddingBottom="20px"
                  height="100%"
                >
                  {loadingAlerts || loadingAgency || loadingAgentProfile ? (
                    <RectangleSkeletons totalRows={3} height={64} />
                  ) : dashboardAlert?.alerts
                      ?.pending_enrollment_patients_count ||
                    dashboardAlert?.alerts?.inactive_patients_count ||
                    dashboardAlert?.alerts
                      ?.pending_interactive_communication_patients_count ? (
                    <>
                      {" "}
                      {dashboardAlert?.alerts
                        ?.pending_enrollment_patients_count ? (
                        <AttentionCard
                          assignedToId={agentUser?.agent?.id}
                          selectedView={selectedView}
                          tab="pending_enrollment"
                          title={`${
                            dashboardAlert?.alerts
                              ?.pending_enrollment_patients_count
                          } ${
                            dashboardAlert?.alerts
                              ?.pending_enrollment_patients_count === 1
                              ? "patient"
                              : "patients"
                          }  ${
                            dashboardAlert?.alerts
                              ?.pending_enrollment_patients_count === 1
                              ? "has"
                              : "have"
                          } been added but their enrollment is incomplete.`}
                        />
                      ) : (
                        <></>
                      )}
                      {dashboardAlert?.alerts?.inactive_patients_count ? (
                        <AttentionCard
                          selectedView={selectedView}
                          assignedToId={agentUser?.agent?.id}
                          tab="inactive"
                          title={`${
                            dashboardAlert?.alerts?.inactive_patients_count
                          } ${
                            dashboardAlert?.alerts?.inactive_patients_count ===
                            1
                              ? "Patient"
                              : "Patients"
                          } ${
                            dashboardAlert?.alerts?.inactive_patients_count ===
                            1
                              ? "hasn't"
                              : "haven't"
                          } logged pain last 7 days.`}
                        />
                      ) : (
                        <></>
                      )}
                      {dashboardAlert?.alerts
                        ?.pending_interactive_communication_patients_count ? (
                        <AttentionCard
                          selectedView={selectedView}
                          assignedToId={agentUser?.agent?.id}
                          tab="pending_communication"
                          title={`You have pending interactive communications for ${
                            dashboardAlert?.alerts
                              ?.pending_interactive_communication_patients_count
                          }  ${
                            dashboardAlert?.alerts
                              ?.pending_interactive_communication_patients_count ===
                            1
                              ? "patient"
                              : "patients"
                          }.`}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <Stack
                      gap="8px"
                      alignItems="center"
                      justifyContent="center"
                      height="100%"
                    >
                      <Box width="100px">
                        <img
                          width="100%"
                          height="100%"
                          src={CaughtUpImage}
                          alt="no alerts"
                        />
                      </Box>
                      <Typography variant="body2">
                        All Set, Nothing to Worry
                      </Typography>
                    </Stack>
                  )}
                </Stack>

                {/* NEED YOUR ATTENTION CARD */}
                {/* {loadingUserFeeds && userFeedNotifications?.length === 0 ? (
                <RectangleSkeletons height={67} />
              ) : userFeedNotifications?.length > 0 ? (
                <div
                  id="scrollableDiv"
                  style={{ overflow: "auto", height: "400px" }}
                >
                  <InfiniteScroll
                    dataLength={userFeedNotifications.length || []}
                    next={fetchMoreData}
                    hasMore={hasNext}
                    loader={
                      <CircularProgressLoading size={20} overflow="hidden" />
                    }
                    scrollableTarget="scrollableDiv"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    {userFeedNotifications?.map((data, index) => (
                      <UserFeedCard data={data} key={index} />
                    ))}
                  </InfiniteScroll>
                </div>
              ) : (
                <Stack
                  gap="16px"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Box width="128px" height="128px">
                    <img src={EmptyBox} alt="empty box" width="100%" />
                  </Box>
                  <Typography variant="body1">No user feeds yet</Typography>
                </Stack>
              )} */}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>

      {/* 3rd ROW CARDS CHAT AND CASES */}
      {/* <Grid container spacing={3}>
          NEW MESSAGES BOX
          <Grid item md={6}>
            <Box
              boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
              borderRadius="8px"
              bgcolor="#FFF"
              height="348px"
              padding="32px"
            >
              <Typography
                variant="h2"
                fontSize="16px"
                fontWeight="500"
                color="secondary"
                textTransform="uppercase"
                lineHeight="28px"
                paddingBottom="24px"
              >
                New Messages
              </Typography>

              {loadingUnreadChatMessages ? (
                <RectangleSkeletons height={78} totalRows={2} />
              ) : unreadChatMessages?.unreadMessages?.length > 0 ? (
                <Stack gap="8px" overflow="auto" height="256px">
                  {unreadChatMessages?.unreadMessages.map((message, i) => (
                    <NewMessageCard
                      key={i}
                      senderProfile={message?.case?.client?.profile_picture_url}
                      senderName={message?.senderDisplayName}
                      chatDate={dateTimeFormat(message?.createdOn)}
                      messageContent={message?.content}
                      case_id={message?.case?.id}
                    />
                  ))}
                </Stack>
              ) : (
                <Stack gap="16px" alignItems="center" justifyContent="center">
                  <Box width="128px" height="128px">
                    <img src={EmptyBox} alt="empty box" width="100%" />
                  </Box>
                  <Typography variant="body1">
                    No chat notifications yet
                  </Typography>
                </Stack>
              )}
            </Box>
          </Grid>

          AT RISK CASES BOX
          <Grid item md={6}>
            <Box
              boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
              borderRadius="8px"
              bgcolor="#FFF"
              height="348px"
              padding="32px"
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                gap="4px"
                alignItems="flex-start"
                paddingBottom="24px"
              >
                <Typography
                  variant="h2"
                  fontSize="16px"
                  fontWeight="500"
                  color="secondary"
                  textTransform="uppercase"
                  lineHeight="28px"
                >
                  At Risk cases
                </Typography>
                <Tooltip
                  title={
                    "Cases with no activity for the last 7 consecutive days are considered 'at risk' and are listed here"
                  }
                  placement="top"
                  arrow
                  PopperProps={{
                    sx: {
                      marginBottom: "-7px !important",
                    },
                  }}
                >
                  <InfoCircle />
                </Tooltip>
              </Box>

              {loadingHighRiskCases ? (
                <RectangleSkeletons height={78} totalRows={2} />
              ) : highRiskCases?.high_risk_cases?.length === 0 ? (
                <Stack gap="16px" alignItems="center" justifyContent="center">
                  <RiskCaseEmptyState />
                  <Typography variant="body1" width="291px" textAlign="center">
                    All your cases are in the clear, no at-risk cases in sight.
                  </Typography>
                </Stack>
              ) : (
                <Stack width="100%" gap="8px" overflow="auto" height="256px">
                  {highRiskCases?.high_risk_cases?.map((caseDetail, i) => (
                    <RiskCasesCard
                      key={i}
                      caseTitle={caseDetail?.title}
                      caseStatus={caseDetail?.status}
                      caseUpdate={timeDateFormat(caseDetail?.updated_at)}
                      profilePhoto={caseDetail?.client?.profile_picture_url}
                      clientName={caseDetail?.client?.name}
                      caseId={caseDetail?.id}
                    />
                  ))}
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid> */}
    </>
  );
};

export default DashboardComponent;
