import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { EocErrorIcon, ReportRefreshIcon } from "../../assets/constants";
import { shortDateFormat } from "../../utils/helpers";
import CircularProgressLoading from "../../common/CircularProgress";
import { useSelector } from "react-redux";

const EOCErrorCard = ({ item, date, onRetryEocHandler }) => {
  const retryEocReport = useSelector((state) => state.retryEocReport);
  const { loading: loadingRetryReport } = retryEocReport;

  const retryEocHandler = () => {
    onRetryEocHandler(item);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap="8px"
      padding="8px 12px"
      borderRadius="8px"
      bgcolor="#FDEDED"
    >
      <Box bgcolor="rgba(255, 89, 89, 0.08)" borderRadius="7px" padding="10px">
        <EocErrorIcon />{" "}
      </Box>

      <Stack flex="1" gap="2px" height="100%" justifyContent="space-evenly">
        <Typography variant="body2" color="#5F2120">
          EOC Export failed
        </Typography>
        <Typography variant="body2" color="#212121">
          Exp By: {item?.agent?.name}{" "}
        </Typography>
      </Stack>

      <Stack gap="2px" alignItems="flex-end">
        {item?.number_of_subreports && (
          <Typography variant="caption" color="#212121">
            {item?.number_of_subreports} Patients
          </Typography>
        )}

        <Typography variant="caption" color="secondary">
          {shortDateFormat(date)}
        </Typography>
      </Stack>

      {loadingRetryReport ? (
        <Box padding="9px">
          <CircularProgressLoading
            color="inherit"
            size={20}
            overflow="hidden"
          />
        </Box>
      ) : (
        <IconButton onClick={retryEocHandler}>
          <ReportRefreshIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default EOCErrorCard;
