import { Avatar, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RectangleSkeletons from "../RectangleSkeletons";
import AutoGeneratedCard from "../AutoGeneratedCard";
import EmptyBox from "../../assets/images/empty-box.png";
import OnDemandEOCCard from "../OnDemandEOCCard";
import dayjs from "dayjs";
import EOCProgressCard from "../EOCProgressCard";
import { useDispatch } from "react-redux";
import {
  getReportsListFunc,
  resetRetryEocReport,
  retryEocReportFunc,
} from "../../actions/Reports";
import EOCErrorCard from "../EOCErrorCard";
import Cookies from "js-cookie";

const AutoGeneratedEOC = ({
  selectedReports,
  setSelectedReports,
  activeTab,
  startDate,
  endDate,
  page,
  assignedTo,
  payerValue,
}) => {
  const getReportsList = useSelector((state) => state.getReportsList);
  const {
    loading: loadingReports,
    error: errorReports,
    reportsList,
  } = getReportsList;

  const [loadingSingleReport, setLoadingSingleReport] = useState(false);

  const dispatch = useDispatch();

  const agencyId = Cookies.get("agency_id_vita");

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const { agencyDetail } = getAgencyDetail;

  const retryEocReport = useSelector((state) => state.retryEocReport);
  const { retriedReport } = retryEocReport;

  const practiceName = agencyDetail?.agency?.name;

  const downloadReportHandler = async (item) => {
    setLoadingSingleReport(true);
    const patientNameFormat =
      activeTab === 0 || activeTab === 1
        ? activeTab === 0
          ? item?.file_type === "zip"
            ? `EOC - ${practiceName} - ${dayjs(item?.created_at)?.format(
                "YYYYMMDDhhmmss"
              )}`
            : `EOC - ${item?.patient?.name}`
          : `${item?.patient?.name} - ${item?.month} ${item?.year}`
        : `${item?.patient?.name} - ${item?.month} ${item?.year}`;

    try {
      const response = await fetch(item?.download_url, {
        method: "GET",
        mode: "cors",
      });

      if (!response?.ok) {
        throw new Error("Failed to download");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${patientNameFormat}`;
      document.body.appendChild(link);
      link.click();
      setLoadingSingleReport(false);
      //   setOpenSnackbar(false);

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
    } finally {
      setLoadingSingleReport(false);
    }
  };

  const checkedReportHandler = (item) => {
    setSelectedReports((prevState) =>
      prevState.some((i) => i?.id === item?.id)
        ? prevState?.filter((i) => i?.id !== item?.id)
        : [...prevState, item]
    );
  };

  useEffect(() => {
    const hasInProgress = reportsList?.reports?.some(
      (item) => item?.status === "in_progress"
    );

    if (hasInProgress) {
      const intervalId = setInterval(() => {
        const reportObj = {
          agency_id: agencyId,
          page: page + 1,
          report_type: "eoc",
          report_generation_type: "on_demand",
          start_date: startDate,
          end_date: endDate,
          assigned_to: assignedTo,
          payer: payerValue === "All" ? "" : payerValue,
        };
        dispatch(getReportsListFunc(reportObj));
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [reportsList]);

  const onRetryEocHandler = (item) => {
    const retryReportObj = {
      report_id: item?.id,
    };
    dispatch(retryEocReportFunc(retryReportObj));
  };

  useEffect(() => {
    if (retriedReport?.ok) {
      const reportObj = {
        agency_id: agencyId,
        page: page + 1,
        report_type: "eoc",
        report_generation_type: "on_demand",
        start_date: startDate,
        end_date: endDate,
        assigned_to: assignedTo,
        payer: payerValue === "All" ? "" : payerValue,
      };
      dispatch(getReportsListFunc(reportObj));
      dispatch(resetRetryEocReport());
    }
  }, [retriedReport]);

  return (
    <Stack
      gap="8px"
      height="100%"
      paddingBottom="20px"
      overflow="auto"
      p="24px"
    >
      {loadingReports ? (
        <RectangleSkeletons totalRows={5} height="61px" />
      ) : reportsList?.reports?.length ? (
        reportsList?.reports?.map((item, index) => {
          let CardComponent;
          if (item?.status === "in_progress") {
            CardComponent = EOCProgressCard;
          } else if (item?.status === "error") {
            CardComponent = EOCErrorCard;
          } else {
            CardComponent =
              activeTab === 0 ? OnDemandEOCCard : AutoGeneratedCard;
          }

          return (
            <CardComponent
              key={index}
              name={item?.patient?.name}
              date={item?.created_at}
              selectedReports={selectedReports}
              id={item?.id}
              year={item?.year}
              month={item?.month}
              item={item}
              downloadReportHandler={downloadReportHandler}
              onToggleReport={checkedReportHandler}
              onRetryEocHandler={onRetryEocHandler}
              loadingSingleReport={loadingSingleReport}
            />
          );
        })
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          height="100%"
          gap="8px"
        >
          <Avatar
            src={EmptyBox}
            alt="no reports found"
            sx={{
              width: "81px",
              height: "81px",
            }}
          />
          <Typography
            variant="caption"
            color="secondary"
            textAlign="center"
            width="330px"
          >
            {activeTab === 1
              ? "Evidence of Care reports will be automatically generated at the end of the month."
              : "No on-demand Evidence of Care reports have been exported yet. When a report is exported, it will appear here."}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default AutoGeneratedEOC;
