// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  @page {
    size: A4;
  }
  body {
    print-color-adjust: exact; /* Standard property */
    -webkit-print-color-adjust: exact;
  }
}

.img-preview {
  overflow: hidden;
}

.square_ripple {
  border-radius: 4px !important;
}

/* #custom-tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  padding: 8px;
  pointer-events: none;  
  z-index: 10;  
} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE;IACE,QAAQ;EACV;EACA;IACE,yBAAyB,EAAE,sBAAsB;IACjD,iCAAiC;EACnC;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;;;;;;;;GAQG","sourcesContent":["@media print {\n  @page {\n    size: A4;\n  }\n  body {\n    print-color-adjust: exact; /* Standard property */\n    -webkit-print-color-adjust: exact;\n  }\n}\n\n.img-preview {\n  overflow: hidden;\n}\n\n.square_ripple {\n  border-radius: 4px !important;\n}\n\n/* #custom-tooltip {\n  position: absolute;\n  background: rgba(0, 0, 0, 0.8);\n  color: white;\n  border-radius: 4px;\n  padding: 8px;\n  pointer-events: none;  \n  z-index: 10;  \n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
