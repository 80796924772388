import React from "react";
import { Box, createTheme, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import RectangleSkeletons from "../RectangleSkeletons";

const CptSummaryBox = () => {
  const theme = createTheme();

  const StyledBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    gap: "32px",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      gap: "4px",
      alignItems: "flex-start",
    },
  }));

  const StyledCptItemBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    gap: "16px",
    justifyContent: "space-between",
    width: "50%",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      //   flexDirection: "column",
      //   gap: "4px",
      //   alignItems: "flex-start",
    },
  }));

  const getCptCodeSummary = useSelector((state) => state.getCptCodeSummary);
  const { loading: loadingCptSummary, cptCodeSummary } = getCptCodeSummary;

  const cptCountMap = {};
  cptCodeSummary?.cpt_summary.forEach(({ cpt_code, count }) => {
    cptCountMap[cpt_code] = count;
  });

  return (
    <Stack
      padding="12px"
      borderRadius="8px"
      border="1px solid #EBEBEB"
      margin="16px 24px"
      gap="4px"
    >
      {/* ROW 1 */}
      <StyledBox>
        <StyledCptItemBox
          display="flex"
          alignItems="center"
          gap="16px"
          justifyContent="space-between"
          width="50%"
        >
          <Typography variant="body1"> CPT Code 98975</Typography>
          {loadingCptSummary ? (
            <RectangleSkeletons totalRows={1} height={24} />
          ) : (
            <Typography variant="body1" color="secondary">
              {" "}
              {cptCountMap["98975"]}
            </Typography>
          )}
        </StyledCptItemBox>
        <StyledCptItemBox
          display="flex"
          alignItems="center"
          gap="16px"
          justifyContent="space-between"
          width="50%"
        >
          <Typography variant="body1"> CPT Code 98980</Typography>
          {loadingCptSummary ? (
            <RectangleSkeletons totalRows={1} height={24} />
          ) : (
            <Typography variant="body1" color="secondary">
              {" "}
              {cptCountMap["98980"]}
            </Typography>
          )}
        </StyledCptItemBox>
      </StyledBox>

      {/* ROW 2 */}
      <StyledBox>
        <StyledCptItemBox
          display="flex"
          alignItems="center"
          gap="16px"
          justifyContent="space-between"
          width="50%"
        >
          <Typography variant="body1"> CPT Code 98977</Typography>
          {loadingCptSummary ? (
            <RectangleSkeletons totalRows={1} height={24} />
          ) : (
            <Typography variant="body1" color="secondary">
              {" "}
              {cptCountMap["98977"]}
            </Typography>
          )}
        </StyledCptItemBox>
        <StyledCptItemBox
          display="flex"
          alignItems="center"
          gap="16px"
          justifyContent="space-between"
          width="50%"
        >
          <Typography variant="body1"> CPT Code 98981</Typography>
          {loadingCptSummary ? (
            <RectangleSkeletons totalRows={1} height={24} />
          ) : (
            <Typography variant="body1" color="secondary">
              {" "}
              {cptCountMap["98981"]}
            </Typography>
          )}
        </StyledCptItemBox>
      </StyledBox>
    </Stack>
  );
};

export default CptSummaryBox;
