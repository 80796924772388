import React, { useEffect } from "react";
import BgBox from "../../../common/BgBox";
import CardUI from "../../../common/Card";
import { Box, Grid, Typography } from "@mui/material";
import HeaderText from "../../../common/HeaderText";
import ChckedImg from "../../../assets/images/checked-image.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAgencyDetailFunc, getAgentProfile } from "../../../actions/Agency";
import {
  segmentTrack,
  segmentIdentify,
} from "../../../utils/helpers/tracking_utils";

const PasswordCreated = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentIdentify({
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
      segmentTrack({
        event: "signed_up",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  useEffect(() => {
    setTimeout(() => {
      navigate("/patients");
    }, 3000);
    dispatch(getAgencyDetailFunc());
    dispatch(getAgentProfile());
  }, []);

  useEffect(() => {
    if (location?.state === null) {
      navigate(-1);
    }
  }, []);

  if (location.state === null) {
    return;
  }

  return (
    <BgBox>
      <CardUI maxWidth={570} paddingTop="86px">
        <Grid container component="main">
          <Box
            xs={12}
            display="flex"
            gap="40px"
            flexDirection="column"
            width="100%"
          >
            <Box
              textAlign="center"
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap="52px"
            >
              <img src={ChckedImg} alt="checked-icon" />
              <Box>
                <HeaderText
                  variant="h3"
                  fontSize={48}
                  textContent="You are all set!"
                  textAlign="center"
                />
                <Typography
                  variant="body1"
                  mt="16px"
                  fontSize={24}
                  color="#000"
                >
                  We are happy to see you on board.
                </Typography>
              </Box>
            </Box>

            <Typography
              variant="body1"
              textAlign="center"
              fontSize={16}
              //   width="75%"
              //   margin="0 auto"
              color="#566875"
            >
              We are redirecting you to VitaTrax Dashboard in 3 seconds
            </Typography>
          </Box>
        </Grid>
      </CardUI>
    </BgBox>
  );
};

export default PasswordCreated;
