export { ReactComponent as SignInVector } from "../images/signin.svg";
export { ReactComponent as SignUpVector } from "../images/signup.svg";
export { ReactComponent as DashboardIcon } from "../images/Dashboard-icon.svg";
export { ReactComponent as CasesIcon } from "../images/cases-icon.svg";
export { ReactComponent as ClientsIcon } from "../images/clients-icon.svg";
export { ReactComponent as VendorsIcon } from "../images/vendors-icon.svg";
export { ReactComponent as ReferralsIcon } from "../images/referrals-icon.svg";
export { ReactComponent as MarketingIcon } from "../images/marketing-icon.svg";
export { ReactComponent as SettingsIcon } from "../images/setting-icon.svg";
export { ReactComponent as TeamTabIcon } from "../images/team-tab-icon.svg";
export { ReactComponent as GeneralTabIcon } from "../images/general-tab-icon.svg";
export { ReactComponent as CasesTabIcon } from "../images/case-tab-icon.svg";
export { ReactComponent as EditIcon } from "../images/edit-icon.svg";
export { ReactComponent as DeleteIcon } from "../images/delete-icon.svg";
export { ReactComponent as BrandingIcon } from "../images/branding-icon.svg";
export { ReactComponent as PushNotificationIcon } from "../images/push-notifications-icon.svg";
export { ReactComponent as EmailMarketingIcon } from "../images/email-marketing-icon.svg";
export { ReactComponent as SecurityIcon } from "../images/security-icon.svg";
export { ReactComponent as SendClientIcon } from "../images/send-client-icon.svg";
export { ReactComponent as NotificationIcon } from "../images/notification.svg";
export { ReactComponent as HelpIcon } from "../images/help-icon.svg";
export { ReactComponent as Logo } from "../images/firm-logo.svg";
export { ReactComponent as GoogleBtn } from "../images/googleBtn.svg";
export { ReactComponent as AddLogoIcon } from "../images/add-logo-icon.svg";
export { ReactComponent as GoogleIcon } from "../images/google-icon.svg";
export { ReactComponent as UserTagIcon } from "../images/user-tag.svg";
export { ReactComponent as LogoutIcon } from "../images/logout.svg";
export { ReactComponent as FolderIcon } from "../images/folder-icon.svg";
export { ReactComponent as SendIcon } from "../images/send-icon.svg";
export { ReactComponent as UploadIcon } from "../images/upload-icon.svg";
export { ReactComponent as ArrowLeftIcon } from "../images/arrow-left.svg";
export { ReactComponent as PrinterIcon } from "../images/printer.svg";
export { ReactComponent as ChatIcon } from "../images/chat-icon.svg";
export { ReactComponent as FolderGreyIcon } from "../images/folder-grey-icon.svg";
export { ReactComponent as MoreInfoIcon } from "../images/more-info-icon.svg";
export { ReactComponent as StepperFolderIcon } from "../images/stepper-folder-icon.svg";
export { ReactComponent as StepperCalendarIcon } from "../images/stepper-calendar-icon.svg";
export { ReactComponent as StepperMessageIcon } from "../images/stepper-message-icon.svg";
export { ReactComponent as StepperCaseIcon } from "../images/stepper-case-icon.svg";
export { ReactComponent as StepperUserIcon } from "../images/stepper-user-icon.svg";
export { ReactComponent as DoubleCheckIcon } from "../images/double-check-icon.svg";
export { ReactComponent as InfoCircle } from "../images/info-circle.svg";
export { ReactComponent as DocCircle } from "../images/clock.svg";
export { ReactComponent as MessageFav } from "../images/message-favorite.svg";
export { ReactComponent as RiskCaseEmptyState } from "../images/empty-state-riskcases.svg";
export { ReactComponent as BillingIcon } from "../images/billing-icon.svg";
export { ReactComponent as InfoIcon } from "../images/info-icon.svg";
export { ReactComponent as CptClockIcon } from "../images/cpt-clock-icon.svg";
export { ReactComponent as TimerIcon } from "../images/timer.svg";
export { ReactComponent as RefreshIcon } from "../images/refresh.svg";
export { ReactComponent as AddPractiseIcon } from "../images/add_practise_icon.svg";
export { ReactComponent as AddPractiseIconBlack } from "../images/add-practise-icon.svg";
export { ReactComponent as BillingReceiptIcon } from "../images/billing-receipt-item.svg";
export { ReactComponent as TickCircleIcon } from "../images/tick-circle-icon.svg";
export { ReactComponent as PushNotificationWhiteIcon } from "../images/pushnotificationicon.svg";
export { ReactComponent as SettingPatientIcon } from "../images/setting-patients-icon.svg";
export { ReactComponent as AddLogo } from "../images/add-logo.svg";
export { ReactComponent as DeleteLogoIcon } from "../images/trash.svg";
export { ReactComponent as PendingIcon } from "../images/pending-icon.svg";
export { ReactComponent as InactiveIcon } from "../images/inactive-icon.svg";
export { ReactComponent as ActiveIcon } from "../images/active-icon.svg";
export { ReactComponent as SmsIcon } from "../images/sms.svg";
export { ReactComponent as DownloadingIcon } from "../images/arrow-down.svg";
export { ReactComponent as DownloadedReport } from "../images/downloadedReport.svg";
export { ReactComponent as ReportErrorWarning } from "../images/reportErrorWarning.svg";
export { ReactComponent as WarningUnenrollIcon } from "../images/warning-unenroll-icon.svg";
export { ReactComponent as AttentionIcon } from "../images/attentionIcon.svg";
export { ReactComponent as ImportIcon } from "../images/import_icon.svg";
export { ReactComponent as ImportBoxIcon } from "../images/importboxicon.svg";
export { ReactComponent as AddNoteIcon } from "../images/add_note.svg";
export { ReactComponent as SendMessageIcon } from "../images/send_message_icon.svg";
export { ReactComponent as AddTimeIcon } from "../images/add_time_icon.svg";
export { ReactComponent as EditPracticeIcon } from "../images/edit_practice_icon.svg";
export { ReactComponent as BillingReportIcon } from "../images/billing-report-icon.svg";
export { ReactComponent as DownloadBillIcon } from "../images/download-bill-icon.svg";
export { ReactComponent as PracticeBillingIcon } from "../images/billing-practice-icon.svg";
export { ReactComponent as ImportPatientsIcon } from "../images/import_patients_icon.svg";
export { ReactComponent as FlashIcon } from "../images/flash_icon.svg";
export { ReactComponent as AddTaskIcon } from "../images/task-square.svg";
export { ReactComponent as AddNoteBulkIcon } from "../images/add_note_icon.svg";
export { ReactComponent as ClockIcon } from "../images/clock_icon.svg";
export { ReactComponent as AppointmentIcon } from "../images/appointment_icon.svg";
export { ReactComponent as ReminderIcon } from "../images/reminder_icon.svg";
export { ReactComponent as ExportIcon } from "../images/export_icon.svg";
export { ReactComponent as BarCodeIcon } from "../images/barcode_icon.svg";
export { ReactComponent as CopyIcon } from "../images/copy_icon.svg";
export { ReactComponent as HomeCard1Icon } from "../images/home_card1_icon.svg";
export { ReactComponent as HomeCard2Icon } from "../images/home_card2_icon.svg";
export { ReactComponent as QrCodeDownloadIcon } from "../images/qrcode_icon.svg";
export { ReactComponent as ImportMaximiseIcon } from "../images/importMaximiseIcon.svg";
export { ReactComponent as AppStoreImage } from "../images/app_store_image.svg";
export { ReactComponent as PinnedNoteInfoIcon } from "../images/pinned_note_info_icon.svg";
export { ReactComponent as EvidenceDownloadIcon } from "../images/evidence-download-icon.svg";
export { ReactComponent as EocDownloadIcon } from "../images/eoc-download-icon.svg";
export { ReactComponent as EocErrorIcon } from "../images/eoc-error-icon.svg";
export { ReactComponent as TimeLineClockIcon } from "../images/timeline-clock-icon.svg";
export { ReactComponent as ReportRefreshIcon } from "../images/reportRefreshIcon.svg";
