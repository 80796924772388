import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const CustomSelectFilter = ({
  menuItems,
  changeSelectValueHandler,
  selectValue,
  id,
  label,
  minWidth = 178,
  placeholder,
  size = "small",
  disabled = false,
  fontSize = "1rem",
  IconComponent,
  iconColor,
  multiple = false,
  MenuProps,
}) => {
  return (
    <FormControl sx={{ width: minWidth }} size={size} disabled={disabled}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        // sx={{
        //   background: placeholder && "#2196F3",
        //   color: placeholder && "#fff",
        //   "& svg": {
        //     color: placeholder && "#fff",
        //   },
        //   "& .MuiOutlinedInput-notchedOutline": {
        //     borderColor: placeholder && "#2196F3 !important",
        //     "& fieldset": {
        //       borderColor: placeholder && "#2196F3 !important",
        //     },
        //     "&:hover fieldset": {
        //       borderColor: placeholder && "#2196F3 !important",
        //     },
        //     "&.Mui-focused fieldset": {
        //       borderColor: placeholder && "#2196F3 !important",
        //     },
        //   },
        // }}
        sx={{
          fontSize: fontSize,
          "& .MuiSvgIcon-root": {
            color: iconColor && iconColor,
          },
        }}
        labelId={id}
        displayEmpty={placeholder ? true : false}
        placeholder={placeholder && placeholder}
        id={id}
        multiple={multiple}
        value={selectValue}
        label={label && label}
        onChange={changeSelectValueHandler}
        variant="outlined"
        IconComponent={IconComponent && IconComponent}
        MenuProps={MenuProps}
      >
        {placeholder && (
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {/* <MenuItem value="all">All</MenuItem> */}
        {menuItems?.map((item, index) => (
          <MenuItem key={index} value={item?.value}>
            {item?.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelectFilter;
