export const GET_REPORTS_LIST_REQUEST = "GET_REPORTS_LIST_REQUEST";
export const GET_REPORTS_LIST_SUCCESS = "GET_REPORTS_LIST_SUCCESS";
export const GET_REPORTS_LIST_FAIL = "GET_REPORTS_LIST_FAIL";

export const GET_REPORT_STATS_REQUEST = "GET_REPORT_STATS_REQUEST";
export const GET_REPORT_STATS_SUCCESS = "GET_REPORT_STATS_SUCCESS";
export const GET_REPORT_STATS_FAIL = "GET_REPORT_STATS_FAIL";

export const GET_REPORT_CPT_CODES_LIST_REQUEST =
  "GET_REPORT_CPT_CODES_LIST_REQUEST";
export const GET_REPORT_CPT_CODES_LIST_SUCCESS =
  "GET_REPORT_CPT_CODES_LIST_SUCCESS";
export const GET_REPORT_CPT_CODES_LIST_FAIL = "GET_REPORT_CPT_CODES_LIST_FAIL";

export const GET_ALL_PAYERS_REQUEST = "GET_ALL_PAYERS_REQUEST";
export const GET_ALL_PAYERS_SUCCESS = "GET_ALL_PAYERS_SUCCESS";
export const GET_ALL_PAYERS_FAIL = "GET_ALL_PAYERS_FAIL";

export const DOWNLOAD_ALL_REPORT_REQUEST = "DOWNLOAD_ALL_REPORT_REQUEST";
export const DOWNLOAD_ALL_REPORT_SUCCESS = "DOWNLOAD_ALL_REPORT_SUCCESS";
export const DOWNLOAD_ALL_REPORT_FAIL = "DOWNLOAD_ALL_REPORT_FAIL";
export const RESET_DOWNLOAD_ALL_REPORT = "RESET_DOWNLOAD_ALL_REPORT";

export const RETRY_EOC_REPORT_REQUEST = "RETRY_EOC_REPORT_REQUEST";
export const RETRY_EOC_REPORT_SUCCESS = "RETRY_EOC_REPORT_SUCCESS";
export const RETRY_EOC_REPORT_FAIL = "RETRY_EOC_REPORT_FAIL";
export const RESET_RETRY_EOC_REPORT = "RESET_RETRY_EOC_REPORT";

export const GET_CPT_TABS_COUNT_REQUEST = "GET_CPT_TABS_COUNT_REQUEST";
export const GET_CPT_TABS_COUNT_SUCCESS = "GET_CPT_TABS_COUNT_SUCCESS";
export const GET_CPT_TABS_COUNT_FAIL = "GET_CPT_TABS_COUNT_FAIL";
// export const RESET_GET_CPT_TABS_COUNT = "RESET_GET_CPT_TABS_COUNT";

export const GET_CPT_CODES_SUMMARY_REQUEST = "GET_CPT_CODES_SUMMARY_REQUEST";
export const GET_CPT_CODES_SUMMARY_SUCCESS = "GET_CPT_CODES_SUMMARY_SUCCESS";
export const GET_CPT_CODES_SUMMARY_FAIL = "GET_CPT_CODES_SUMMARY_FAIL";
